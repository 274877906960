import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetOrdersQuery } from '../../../api/api'
import NoDataFoundTable from '../../../utils/components/table/PortalNoDataFoundTable'
import PortalPagination from '../../../utils/components/PortalPagination'
import PortalPill from '../../../utils/components/PortalPill'
import { formatStatus } from '../../../utils/formatStatus'
import PortalLoader from '../../../utils/components/PortalLoader'
import { Link } from 'react-router-dom'

const OrdersTable = ({
  startDate,
  endDate,
  search,
}: {
  startDate?: Date | null
  endDate?: Date | null
  search: string | null
}) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const columns = [
    t('orderTable.createDate'),
    t('orderTable.orderNumber'),
    t('orderTable.orderType'),
    t('orderTable.shippedFrom'),
    t('orderTable.shippedTo'),
    t('orderTable.units'),
    t('orderTable.status'),
  ]
  const { data, isLoading } = useGetOrdersQuery({
    page,
    startDate,
    endDate,
    search,
  })

  if (isLoading) {
    return <PortalLoader />
  }

  return (
    <div className="table-responsive">
      <table className="w-100">
        <thead className="table-thead">
          <tr>
            {columns.map((column) => (
              <th key={column as string} scope="col" className="text-center">
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {!isLoading && !data?.resources?.length && (
            <tr>
              <td colSpan={columns.length} className="text-center">
                <NoDataFoundTable />
              </td>
            </tr>
          )}

          {!isLoading &&
            data?.resources?.map((order, index) => {
              const { object, createdAt, shipedFrom, shippedTo } = order
              return (
                <tr key={index} className="table-tr">
                  <td className="text-center">{createdAt}</td>
                  <td className="text-center">
                    <Link to={`/orders/order/${object.id}`}>#{object.id}</Link>
                  </td>
                  <td className="text-center">{object.orderType}</td>
                  <td className="text-center">{shipedFrom}</td>
                  <td className="text-center">{shippedTo}</td>
                  <td className="text-center">#{object.garmentsNumber}</td>
                  <td className="text-center">
                    <PortalPill
                      text={formatStatus({ text: object.orderStatus })}
                    />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      {data?.meta && !isLoading && (
        <PortalPagination
          meta={data?.meta}
          onChangePage={setPage}
          page={page}
        />
      )}
    </div>
  )
}

export default OrdersTable
