import React, { useEffect, useState } from 'react'
import { Pagination } from '../../api/interfaces'

const PortalPagination = ({
  onChangePage,
  meta,
  page,
}: {
  onChangePage: (p: number) => void
  meta: Pagination
  page: number
}) => {
  const [prev, setPrev] = useState<number | null>(null)
  const [next, setNext] = useState<number | null>(null)
  const [last, setLast] = useState<number | null>(null)

  useEffect(() => {
    setPrev(meta.pagination.prev.number)
    setNext(meta.pagination.next.number)
    setLast(meta.pagination.last.number)
  }, [meta])

  return (
    <div className="d-flex justify-content-end mt-2">
      <ul className="pagination">
        {!prev ? null : (
          <>
            <li className="page-item" onClick={() => onChangePage(1)}>
              <span className="page-link">{'<< First'}</span>
            </li>
            <li className="page-item" onClick={() => onChangePage(page - 1)}>
              <span className="page-link">{'< Prev'}</span>
            </li>
            <li className="page-item" onClick={() => onChangePage(page - 1)}>
              <span className="page-link">{page - 1}</span>
            </li>
          </>
        )}
        <li className="page-item active">
          <span className="page-link">{page}</span>
        </li>

        {next && (
          <>
            <li className="page-item" onClick={() => onChangePage(page + 1)}>
              <span className="page-link">{page + 1}</span>
            </li>
            <li className="page-item disabled">
              <span className="page-link">{'...'}</span>
            </li>
            <li className="page-item" onClick={() => onChangePage(page + 1)}>
              <span className="page-link">{'Next >'}</span>
            </li>
            <li
              className="page-item"
              onClick={() => onChangePage(last as number)}
            >
              <span className="page-link">{'Last >>'}</span>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}

export default PortalPagination
