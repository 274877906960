import React, { useEffect, ReactNode, useContext } from 'react'
import { PortalContext } from '../../PortalContext'
import LogoutButton from './LogoutButton'
import SidebarButton from './SidebarButton'
import { useNavigate } from 'react-router-dom'
import SidebarDropdown from './SidebarDropdown'
import SidebarLocation from './SidebarLocation'
import {
  MultiSelectOption,
  SidebarOption,
  sidebarOptions,
} from './sidebarOptions'
import PortalIcon from '../../utils/components/PortalIcon'

const Sidebar = ({ children }: { children: ReactNode }) => {
  const context = useContext(PortalContext)
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/${context?.pathSelected}`)
  }, [context?.pathSelected])

  return (
    <div>
      <div className={`sidebar ${!context?.isMobile ? 'open' : 'closed'} mr-2`}>
        <div className="sidebar-content">
          <h1 className="logo mb-5">
            <PortalIcon name="re" height={60} />
          </h1>
          <span className="brand mt-5">{'[Brand]'}</span>
          <SidebarLocation />
          {sidebarOptions().map((option: SidebarOption) => {
            const { isMultiSelect, path, title, icon, showHr, options } = option
            return (
              <div key={title}>
                {isMultiSelect ? (
                  <SidebarDropdown
                    page={title}
                    basePath={path}
                    icon={icon}
                    options={options as Array<MultiSelectOption>}
                  />
                ) : (
                  <SidebarButton page={title} path={path} icon={icon} />
                )}
                {showHr ? <hr /> : null}
              </div>
            )
          })}
        </div>
        <div className="m-3">
          <LogoutButton />
        </div>
      </div>
      <main className={`main ${!context?.isMobile ? 'open' : 'closed'}`}>
        <div>{children}</div>
      </main>
    </div>
  )
}

export default Sidebar
