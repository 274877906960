import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import PortalLoader from '../../../utils/components/PortalLoader'
import { useGetUnitQuery } from '../../../api/api'
import PortalAlertMessage from '../../../utils/components/PortalAlertMessage'
import Breadcrumb from '../../../utils/components/PortalBreadcrumb'
import { ServiceRepair } from '../../../types/interfaces'

const UnitPage = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const { data: unit, isLoading: isUnitLoading } = useGetUnitQuery(id || '')

  const breadcrumbLinks = [
    { label: t('unitShow.breadcrumb.units'), path: '/orders/units' },
    {
      label: t('unitShow.breadcrumb.skuOrID', {
        sku: unit?.sku,
        garmentId: unit?.garmentId,
      }),
      path: '',
    },
  ]

  if (isUnitLoading) return <PortalLoader />

  return (
    <div>
      <Breadcrumb links={breadcrumbLinks} />

      <div className="card-container">
        <h1>{t('unitShow.portalUnitSections.unitDetails.title')}</h1>

        {!unit.sku_library && (
          <PortalAlertMessage
            text={t(
              'unitShow.portalUnitSections.unitDetails.messages.skuNotFoundSkuLibrary',
            )}
            color="alert-message-danger"
          />
        )}

        <div className="d-inline-block d-display">
          <div className="card-detail">
            <strong>
              {t('unitShow.portalUnitSections.unitDetails.fields.garmentId')}
            </strong>
            <span className="ps-4">{unit?.garment_id}</span>
          </div>
          <div className="card-detail">
            <strong>
              {t('unitShow.portalUnitSections.unitDetails.fields.skuUpcCode')}
            </strong>

            <span className="ps-4">
              {unit?.sku_library ? (
                <PortalAlertMessage
                  text={t(
                    'unitShow.portalUnitSections.unitDetails.messages.skuNotFound',
                  )}
                  color="alert-message-danger"
                />
              ) : (
                unit.sku_upc_code
              )}
            </span>
          </div>
          <div className="card-detail">
            <strong>
              {t('unitShow.portalUnitSections.unitDetails.fields.orderN')}
            </strong>
            <span className="ps-4">{unit?.order_number}</span>
          </div>
          <div className="card-detail">
            <strong>
              {t('unitShow.portalUnitSections.unitDetails.fields.brand')}
            </strong>
            <span className="ps-4">{unit?.brand}</span>
          </div>
          <div className="card-detail">
            <strong>
              {t('unitShow.portalUnitSections.unitDetails.fields.garmentType')}
            </strong>
            <span className="ps-4">{unit?.garment_type}</span>
          </div>
          <div className="card-detail">
            <strong>
              {t(
                'unitShow.portalUnitSections.unitDetails.fields.styleNameDescription',
              )}
            </strong>
            <span className="ps-4">{unit?.style_name_description}</span>
          </div>
          <div className="card-detail">
            <strong>
              {t('unitShow.portalUnitSections.unitDetails.fields.material')}
            </strong>
            <span className="ps-4">{unit?.material}</span>
          </div>
          <div className="card-detail">
            <strong>
              {t('unitShow.portalUnitSections.unitDetails.fields.size')}
            </strong>
            <span className="ps-4">{unit?.size}</span>
          </div>
          <div className="card-detail">
            <strong>
              {t('unitShow.portalUnitSections.unitDetails.fields.color')}
            </strong>
            <span className="ps-4">{unit?.color}</span>
          </div>
        </div>
      </div>

      <div className="card-container mt-4">
        <h1>{t('unitShow.portalUnitSections.services.title')}</h1>

        <div className="d-inline-block d-display">
          <div className="card-detail">
            <p className="retairs-title">
              {t('unitShow.portalUnitSections.services.fields.repairs')}
            </p>
            <span className="retairs-list">
              {!isUnitLoading &&
                unit?.repairs?.map((repair: ServiceRepair, index: number) => (
                  <p key={index} className="text-center m-2">
                    {repair?.description}
                  </p>
                ))}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnitPage
