import { OrderStatusTracker } from '../api/interfaces'
import { formatStatus } from './formatStatus'

const orders = [
  'created',
  'in_transit',
  'arrived',
  'in_progress',
  'revive_complete',
  'packaged_units',
  'all_units_packaged',
  'cancelled',
]

export const formatOrderStatusTracker = ({
  trackerList,
}: {
  trackerList: OrderStatusTracker[]
}) => {
  return trackerList
    .filter((item) => orders.includes(item.partnerStatus))
    .sort((a, b) => {
      return orders.indexOf(a.partnerStatus) - orders.indexOf(b.partnerStatus)
    })
    .map(
      ({ ordersTotal, partnerStatus, garmentsTotal }) =>
        `${ordersTotal} ${garmentsTotal ? `(${garmentsTotal}u)` : ''} ${formatStatus({ text: partnerStatus })}`,
    )
}
