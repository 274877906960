import React, { useContext, useEffect, useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import SidebarButton from './SidebarButton'
import { PortalContext } from '../../PortalContext'
import PortalIcon from '../../utils/components/PortalIcon'
import { MultiSelectOption } from './sidebarOptions'

const SidebarDropdown = ({
  page,
  basePath,
  icon,
  options,
}: {
  page: string
  basePath: string
  icon?: string
  options: Array<MultiSelectOption>
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const context = useContext(PortalContext)
  useEffect(() => {
    const selected = basePath === context?.pathSelected?.split('/')?.at(0)
    setIsSelected(selected)
    setIsOpen(selected)
  }, [context?.pathSelected])

  if (!context?.isMobile) {
    return (
      <DesktopSidebarDropdown
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        icon={icon}
        text={page}
        basePath={basePath}
        options={options}
      />
    )
  } else {
    return (
      <MobileSidebarDropdown
        icon={icon}
        isSelected={isSelected}
        options={options}
        title={page}
        basePath={basePath}
      />
    )
  }
}

const DesktopSidebarDropdown = ({
  setIsOpen,
  isOpen,
  icon,
  text,
  basePath,
  options,
}: {
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  icon?: string
  text: string
  basePath: string
  options: Array<MultiSelectOption>
}) => {
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`btn btn-sm w-100 sidebar-button`}
        id="sidebar-collapse"
      >
        {icon ? (
          <PortalIcon
            name={icon}
            className="portal-fill-charcoal m-2 mt-1"
            width={14}
            height={14}
          />
        ) : null}
        {text}
        <div className="float-end">
          <PortalIcon
            name={isOpen ? 'openDropdown' : 'closeDropdown'}
            className="m-2 mt-1"
            width={14}
            height={14}
          />
        </div>
      </button>
      <Collapse in={isOpen}>
        <div
          id="sidebar-collapse"
          className="sidebar-collapse border-left-white margin-left-25"
        >
          {options.map((option) => {
            const { title, path } = option
            return (
              <SidebarButton
                key={title}
                path={`${basePath}/${path}`}
                page={title}
              />
            )
          })}
        </div>
      </Collapse>
    </>
  )
}

const MobileSidebarDropdown = ({
  icon,
  isSelected,
  options,
  title,
  basePath,
}: {
  icon?: string
  isSelected: boolean
  options: Array<MultiSelectOption>
  title: string
  basePath: string
}) => {
  return (
    <div className="dropend">
      <button
        className={`btn w-100 sidebar-button closed ${isSelected ? 'selected' : ''}`}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {icon ? (
          <PortalIcon
            name={icon}
            className="portal-fill-charcoal"
            width={14}
            height={14}
          />
        ) : null}
      </button>
      <ul className="dropdown-sidebar dropdown-menu position-fixed w-50">
        <li>
          <a className="dropdown-item d-flex justify-content-center">{title}</a>
          <hr className="w-100 m-0 p-0"></hr>
        </li>
        {options.map((option) => {
          const { title: optionTitle, path } = option
          return (
            <li key={optionTitle} className="d-flex">
              <SidebarButton
                key={title}
                path={`${basePath}/${path}`}
                page={optionTitle}
                fromDropdown={true}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SidebarDropdown
