import React from 'react'
import { Provider } from 'react-redux'
import { store } from './api/store'
import { PortalProvider } from './PortalContext'
import { PortalRoutes } from './PortalRoutes'

const App = () => {
  return (
    <PortalProvider>
      <Provider store={store}>
        <PortalRoutes />
      </Provider>
    </PortalProvider>
  )
}

export default App
