import React from 'react'
import PortalIcon from '../../utils/components/PortalIcon'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NotFoundPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleGoToHome = () => {
    navigate('/home')
  }
  return (
    <div className="portal-center portal-background-offWhite portal-max-height ">
      <div className="h-75 w-50">
        <div className="col-12 d-flex justify-content-center">
          <PortalIcon name="revive" height={100} />
        </div>
        <div className="col-12 mt-5 d-flex justify-content-center">
          <PortalIcon name="alert" height={130} className="mr-20" />
          <h1 className="error-id">404</h1>
        </div>
        <div className="col-12 mt-3 d-flex justify-content-center">
          <h1 className="error-text">{t('errorPage.pageNotFound')}</h1>
        </div>
        <div className="col-12 mt-5 d-flex justify-content-center">
          <span>{t('errorPage.notFoundDescription')}</span>
        </div>
        <div className="col-12 mt-5 d-flex justify-content-center">
          <button
            onClick={handleGoToHome}
            className="btn btn-pill portal-background-charcoal portal-text-white"
          >
            {t('errorPage.backToHome')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
