import React from 'react'
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg'
import { ReactComponent as OrdersIcon } from '../../assets/icons/orders.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg'
import { ReactComponent as CreateNewOrderIcon } from '../../assets/icons/create_new_order.svg'
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg'
import { ReactComponent as OpenDropdownIcon } from '../../assets/icons/open_dropdown.svg'
import { ReactComponent as CloseDropdownIcon } from '../../assets/icons/close_dropdown.svg'
import { ReactComponent as ReviveIcon } from '../../assets/icons/revive.svg'
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg'
import { ReactComponent as ReIcon } from '../../assets/icons/re.svg'
import { ReactComponent as SunIcon } from '../../assets/icons/sun.svg'
import { ReactComponent as NatureEcologyTreePlantPotIcon } from '../../assets/icons/nature_ecology_tree_plant_pot.svg'
import { ReactComponent as InterfaceArrowExpand } from '../../assets/icons/interface_arrow_expand.svg'
import { ReactComponent as WarningTriangle } from '../../assets/icons/warning-triangle.svg'

type IconComponent = React.FC<React.SVGProps<SVGSVGElement>>

const ICONS: Record<string, IconComponent> = {
  home: HomeIcon,
  orders: OrdersIcon,
  logout: LogoutIcon,
  createNewOrder: CreateNewOrderIcon,
  location: LocationIcon,
  openDropdown: OpenDropdownIcon,
  closeDropdown: CloseDropdownIcon,
  revive: ReviveIcon,
  alert: AlertIcon,
  re: ReIcon,
  sun: SunIcon,
  natureEcologyTreePlantPot: NatureEcologyTreePlantPotIcon,
  interfaceArrowExpand: InterfaceArrowExpand,
  warningTriangle: WarningTriangle,
}

const PortalIcon = ({
  name,
  width,
  height,
  className,
}: {
  name: string
  width?: number
  height?: number
  className?: string
  fill?: string
}) => {
  const Icon = ICONS[name]

  if (!Icon) return null

  return <Icon className={className} width={width} height={height} />
}

export default PortalIcon
