import React from 'react'
import { Link } from 'react-router-dom'

export interface LinkBreadcrumb {
  label: string
  path?: string
}

const Breadcrumb = ({ links }: { links: Array<LinkBreadcrumb> }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb font-suisse-regular">
        {links.map((link, index) => (
          <li
            key={index}
            className={`breadcrumb-item ${index === links.length - 1 ? 'active' : ''}`}
          >
            {index === links.length - 1 ? (
              link.label
            ) : (
              <Link to={link.path || ''}>{link.label}</Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumb
