import React, { useContext, useState } from 'react'
import { PortalContext } from '../../PortalContext'
import PortalIcon from '../../utils/components/PortalIcon'

const SidebarButton = ({
  page,
  path,
  className = '',
  icon,
  fromDropdown = false,
}: {
  page: string
  path: string
  className?: string
  icon?: string
  fromDropdown?: boolean
}) => {
  const context = useContext(PortalContext)

  if (!context?.isMobile) {
    return (
      <DesktopSidebarButton
        path={path}
        className={className}
        icon={icon}
        text={page}
      />
    )
  } else {
    return (
      <MobileSidebarButton
        icon={icon}
        path={path}
        title={page}
        fromDropdown={fromDropdown}
      />
    )
  }
}

const DesktopSidebarButton = ({
  path,
  className,
  icon,
  text,
}: {
  path: string
  className: string
  icon?: string
  text: string
}) => {
  const context = useContext(PortalContext)

  const handleSelectPage = async () => {
    context?.setPathSelected(path)
  }

  const isSelected = arePathsEqual({
    path,
    current: context?.pathSelected as string,
  })
  return (
    <button
      onClick={handleSelectPage}
      className={`btn btn-sm w-100 sidebar-button ${isSelected ? 'selected' : ''} ${className}`}
    >
      {icon ? (
        <PortalIcon
          name={icon}
          className={`${isSelected ? 'portal-stroke-charcoal' : 'portal-fill-charcoal'} m-2 mt-1`}
          width={14}
          height={14}
        />
      ) : null}
      {text}
    </button>
  )
}

const MobileSidebarButton = ({
  icon,
  path,
  title,
  fromDropdown,
}: {
  icon?: string
  path: string
  title: string
  fromDropdown: boolean
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const context = useContext(PortalContext)

  const handleSelectPage = async () => {
    context?.setPathSelected(path)
    setDropdownOpen(false)
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const isSelected = arePathsEqual({
    path,
    current: context?.pathSelected as string,
  })
  if (fromDropdown) {
    return (
      <button
        onClick={handleSelectPage}
        className={`btn btn-sm w-100 sidebar-button`}
      >
        {title}
      </button>
    )
  }

  return (
    <div className="dropend">
      <button
        className={`btn w-100 sidebar-button closed ${isSelected ? 'selected' : ''}`}
        data-bs-toggle="dropdown"
        aria-expanded={dropdownOpen}
        onClick={toggleDropdown}
      >
        {icon ? (
          <PortalIcon
            name={icon}
            className={`${isSelected ? 'portal-stroke-charcoal' : 'portal-fill-charcoal'}`}
            width={14}
            height={14}
          />
        ) : null}
      </button>

      <ul
        className={`dropdown-sidebar dropdown-menu position-fixed w-50 ${dropdownOpen ? 'show' : ''}`}
      >
        <li className="d-flex">
          <button
            onClick={handleSelectPage}
            className={`btn btn-sm w-100 sidebar-button`}
          >
            {title}
          </button>
        </li>
      </ul>
    </div>
  )
}

const arePathsEqual = ({
  path,
  current,
}: {
  path: string
  current: string
}) => {
  if (path === current) return true
  const splitedPath = path.split('/')
  const splitedCurrent = current.split('/')
  if (
    splitedCurrent?.[0] === splitedPath?.[0] &&
    splitedCurrent?.[1] ===
      splitedPath?.[1]?.slice(0, splitedCurrent?.[1].length)
  ) {
    return true
  }

  return false
}

export default SidebarButton
