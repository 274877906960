import React from 'react'

const PortalLoader = () => {
  return (
    <div className="portal-max-height d-flex justify-content-center align-items-center">
      <div
        className="spinner-border d-flex justify-content-center align-items-center"
        role="status"
      ></div>
    </div>
  )
}

export default PortalLoader
