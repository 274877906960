import React from 'react'

const PortalStatusTracker = ({ trackerList }: { trackerList: string[] }) => {
  return (
    <div className="status-tracker">
      {trackerList.map((t) => {
        return (
          <div className="tracker-conection" key={trackerList.indexOf(t)}>
            <div className="tracker">
              <span className="text">{t}</span>
            </div>
            {trackerList.indexOf(t) !== trackerList.length - 1 && (
              <div className="line">
                <hr />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default PortalStatusTracker
