import React from 'react'

const PortalButton = ({
  text,
  isLoading,
  onClick = () => {},
  type = 'button',
}: {
  text: string
  isLoading?: boolean
  onClick?: VoidFunction
  type?: 'button' | 'submit' | 'reset' | undefined
}) => {
  return (
    <button
      type={type}
      className="btn portal-button-primary mt-2 w-100 "
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <span
          className="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
      ) : null}
      {text}
    </button>
  )
}

export default PortalButton
