import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  LoginRequest,
  LoginErrorResponse,
  ResourceList,
  OrderStatusTracker,
  Params,
} from './interfaces'
import { Session } from '../PortalContext'
import { OrderClass, OrderInterface } from '../types/order'
import { formatDate } from 'date-fns'

const BASE_URL = process.env.REACT_APP_API_URL

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/partners/v1`,
    credentials: 'include',
    prepareHeaders: (headers) => {
      return headers
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<void, LoginRequest>({
      query: (user) => ({
        url: '/sessions',
        method: 'POST',
        body: user,
      }),
      transformErrorResponse: (response: LoginErrorResponse) => {
        return response.data.error
      },
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/sessions',
        method: 'DELETE',
      }),
    }),
    getSession: builder.query<Session, boolean>({
      query: () => `/sessions`,
    }),
    getStatusTracker: builder.query<OrderStatusTracker[], void>({
      query: () => `/orders/status_tracker`,
    }),
    getOrder: builder.query<OrderClass, { id?: number }>({
      query: ({ id }: { id?: number }) => {
        return `/orders/${id}`
      },
      transformResponse: (data: OrderInterface) => {
        return new OrderClass(data)
      },
    }),
    getOrders: builder.query<ResourceList<OrderClass>, Params>({
      query: ({ page = 1, startDate, endDate, search }) => {
        const params = new URLSearchParams({
          'page[number]': page.toString(),
        })

        if (startDate && endDate) {
          params.append('q[start_date]', formatDate(startDate, 'yyyy-MM-dd'))
          params.append('q[end_date]', formatDate(endDate, 'yyyy-MM-dd'))
        }

        if (search?.length) {
          params.append('q[search]', search)
        }

        return `/orders?${params.toString()}`
      },
      transformResponse: (data: ResourceList<OrderInterface>) => {
        return {
          meta: data?.meta,
          resources: data?.resources.map((order) => {
            return new OrderClass(order)
          }),
        }
      },
    }),
    getUnits: builder.query({
      query: () => `/units`,
    }),
    getUnit: builder.query({
      query: (id: string) => `/units/${id}`,
    }),
  }),
})

export const {
  useLoginMutation,
  useGetSessionQuery,
  useLogoutMutation,
  useGetStatusTrackerQuery,
  useGetOrdersQuery,
  useGetOrderQuery,
  useGetUnitsQuery,
  useGetUnitQuery,
} = api
