import React, { useContext } from 'react'
import PortalIcon from '../../utils/components/PortalIcon'
import { PortalContext } from '../../PortalContext'

const SidebarLocation = () => {
  const context = useContext(PortalContext)

  const selectedlocationId = context?.session?.selectedRetailerLocationId
  const locations = context?.session?.stylistRetailerLocations

  if (!context?.isMobile) {
    return (
      <div className="location mt-3 p-2 mb-5">
        <span>{context?.session?.firstName}</span>
        <br />
        <span>
          <PortalIcon name={'location'} className={''} width={14} height={14} />{' '}
          {/** Show the selected location name, or the first user's location name */}
          {selectedlocationId && locations
            ? locations?.find((l) => l.id === selectedlocationId)?.name
            : locations?.at(0)?.name}
        </span>
      </div>
    )
  }

  return (
    <div className="location pt-2 pb-2 mb-5">
      <PortalIcon name={'location'} className={''} width={14} height={14} />
    </div>
  )
}

export default SidebarLocation
