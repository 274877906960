import { useTranslation } from 'react-i18next'

export interface SidebarOption {
  title: string
  path: string
  icon: string
  showHr?: boolean
  isMultiSelect?: boolean
  options?: Array<MultiSelectOption>
}

export interface MultiSelectOption {
  title: string
  path: string
}
export const sidebarOptions = (): Array<SidebarOption> => {
  const { t } = useTranslation()
  return [
    { title: t('sidebar.home'), path: 'home', icon: 'home' },
    {
      title: t('sidebar.orders'),
      path: 'orders',
      icon: 'orders',
      showHr: true,
      isMultiSelect: true,
      options: [
        { title: t('sidebar.orders'), path: 'orders' },
        { title: t('sidebar.units'), path: 'units' },
      ],
    },
    {
      title: t('sidebar.createNewOrder'),
      path: 'new-order',
      icon: 'createNewOrder',
    },
  ]
}
