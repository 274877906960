import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetOrderQuery } from '../../../api/api'
import PortalLoader from '../../../utils/components/PortalLoader'
import PortalHeader from '../../../utils/components/PortalHeader'

const OrderPage = () => {
  const { id } = useParams()
  const { data, isLoading } = useGetOrderQuery(
    { id: id as number | undefined },
    { skip: !id },
  )
  if (isLoading) return <PortalLoader />

  return (
    <div>
      <PortalHeader
        paths={[
          { text: 'Orders', path: '/orders/orders' },
          { text: `Order#${id}` },
        ]}
      />
      <h3>{data?.createdAt}</h3>

      <div className="card w-100 p-3">
        <h2>Shipment Details</h2>
      </div>
    </div>
  )
}

export default OrderPage
