import React from 'react'
import { useTranslation } from 'react-i18next'
import PortalIcon from '../PortalIcon'

const NoDataFoundTable = () => {
  const { t } = useTranslation()

  return (
    <div className="m-5">
      <PortalIcon name="natureEcologyTreePlantPot" height={50} width={50} />
      <p className="no-data-found-title">{t('table.noDataFound')}</p>
    </div>
  )
}

export default NoDataFoundTable
