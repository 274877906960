import React, { useContext } from 'react'
import { PortalContext } from '../../PortalContext'
import PortalIcon from '../../utils/components/PortalIcon'
import { useTranslation } from 'react-i18next'

const RETAILERS_APP = process.env.REACT_APP_RETAILER_URL

const HomePage = () => {
  const { t } = useTranslation()
  const context = useContext(PortalContext)

  const handleCreateNewOrder = () => {
    window.location.href = RETAILERS_APP as string
  }

  const handleCheckOrderStatus = () => {
    context?.setPathSelected('orders/orders')
  }
  return (
    <div>
      <h1>
        {t('home.welcomeBack')} {context?.session?.firstName}{' '}
        {context?.session?.lastName}
        <PortalIcon name="sun" height={43} className="m-2 mb-3" />
      </h1>
      <div className="card">
        <div className="body p-3">
          <div className="col-12">
            <h2>{t('home.quickTools')}</h2>
          </div>
          <div className="col-12 mt-5">
            <button
              className="btn portal-button-secondary"
              onClick={handleCreateNewOrder}
            >
              {t('home.createNewOrder')}
            </button>
            <button
              className="btn portal-button-secondary mx-2"
              onClick={handleCheckOrderStatus}
            >
              {t('home.checkOrdersStatus')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
