import React, { ReactNode, useContext, useEffect } from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'

import HomePage from './pages/home/HomePage'
import OrdersPage from './pages/orders/orders/OrdersPage'
import UnitsPage from './pages/orders/units/UnitsPage'
import CreateNewOrder from './pages/createNewOrder/CreateNewOrder'
import Login from './pages/login/Login'
import { PortalContext } from './PortalContext'
import Sidebar from './pages/sidebar/Sidebar'
import NotFoundPage from './pages/notFound/NotFoundPage'
import OrderPage from './pages/orders/order/OrderPage'
import UnitPage from './pages/orders/units/UnitPage'

export const PortalRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="orders/orders"
          element={
            <PrivateRoute>
              <OrdersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="orders/units"
          element={
            <PrivateRoute>
              <UnitsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="orders/units/:id"
          element={
            <PrivateRoute>
              <UnitPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/new-order"
          element={
            <PrivateRoute>
              <CreateNewOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="orders/order/:id"
          element={
            <PrivateRoute>
              <OrderPage />
            </PrivateRoute>
          }
        />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </BrowserRouter>
  )
}

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const portalContext = useContext(PortalContext)
  const isAuthenticated = !!portalContext?.session
  const location = useLocation()
  const context = useContext(PortalContext)

  useEffect(() => {
    const currentPath = location.pathname.substring(1)
    if (currentPath.length && context?.pathSelected !== currentPath) {
      context?.setPathSelected(currentPath)
    }
  }, [location])

  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <>
      <Sidebar>
        <div className="p-5">{children}</div>
      </Sidebar>
    </>
  )
}
