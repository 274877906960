import React, { createContext, ReactNode, useEffect, useState } from 'react'

interface RetailerLocation {
  id: number
  name: string
}

export interface Session {
  id: number
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  'canSeeStoreLocationReport?': boolean
  impersonated: boolean
  'retailerEcommApproved?': boolean
  status: string
  retailerName: string
  roles: Array<string>
  address: Array<{
    address1: string
    address2: string
    addressType: string
    country: string
    id: number
    isPrimary: boolean
    postalCode: string
    state: string
    city: string
  }>
  selectedRetailerLocationId: number | null
  stylistRetailerLocations: Array<RetailerLocation>
}

interface SessionContextType {
  session: Session | null | undefined
  setSession: React.Dispatch<React.SetStateAction<Session | null | undefined>>
  pathSelected: string
  setPathSelected: React.Dispatch<React.SetStateAction<string>>
  isMobile: boolean
}

export const PortalContext = createContext<SessionContextType | undefined>(
  undefined,
)

export const PortalProvider = ({ children }: { children: ReactNode }) => {
  const [session, setSession] = useState<Session | null | undefined>(null)
  const [pathSelected, setPathSelected] = useState<string>(
    localStorage.getItem('page') as string,
  )
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    localStorage.setItem('page', pathSelected)
  }, [pathSelected, localStorage.getItem('page')])

  const handleResize = () => {
    setIsMobile(window.innerWidth < 769)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <PortalContext.Provider
      value={{
        session,
        setSession: setSession as React.Dispatch<
          React.SetStateAction<Session | null | undefined>
        >,
        pathSelected,
        setPathSelected,
        isMobile,
      }}
    >
      {children}
    </PortalContext.Provider>
  )
}
