import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const PortalSearch = ({
  value,
  onChange,
}: {
  value: string | null
  onChange: (s: string) => void
}) => {
  const [text, setText] = useState(value)
  const { t } = useTranslation()

  return (
    <>
      <input
        className="form-control w-25"
        placeholder="Search..."
        value={text || ''}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onChange(text || '')
          }
        }}
      />
      <button
        className="btn portal-button-primary portal-ml-10"
        onClick={() => onChange(text || '')}
        disabled={!text?.length}
      >
        {t('actions.search')}
      </button>
    </>
  )
}

export default PortalSearch
