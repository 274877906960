import React, { useContext, useEffect, useState } from 'react'
import image from '../../assets/images/login.png'
import PortalButton from '../../utils/components/PortalButton'
import PortalInput from '../../utils/components/PortalInput'
import { useTranslation } from 'react-i18next'
import { useGetSessionQuery, useLoginMutation } from '../../api/api'
import { PortalContext } from '../../PortalContext'
import { Navigate } from 'react-router-dom'
import { useLoginSchema } from '../../validationSchema/useLoginSchema'
import { useFormik } from 'formik'
import PortalIcon from '../../utils/components/PortalIcon'
import PortalLoader from '../../utils/components/PortalLoader'

const Login = () => {
  const { t } = useTranslation()
  const [isSessionValid, setIsSessionValid] = useState(false)
  const [login] = useLoginMutation()
  const context = useContext(PortalContext)
  const loginSchema = useLoginSchema()
  const {
    data: session,
    error: getSessionError,
    isLoading: isSessionLoading,
  } = useGetSessionQuery(isSessionValid)

  const loginFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setErrors }) => {
      const { email, password } = values
      await login({ user: { email: email.toLowerCase(), password } }).then(
        (data) => {
          if (data.error) {
            setErrors({ email: data.error as string })
          } else {
            setIsSessionValid(!isSessionValid)
          }
        },
      )
    },
  })

  useEffect(() => {
    if (!isSessionLoading && !getSessionError) {
      context?.setSession(session)
    }
  }, [session, context, getSessionError, isSessionLoading])

  if (context?.session) {
    return <Navigate to={`/${context.pathSelected || 'home'}`} />
  }

  if (isSessionLoading || session) return <PortalLoader />

  return (
    <div className="row portal-max-height overflow-hidden">
      <div
        className={`${context?.isMobile ? 'col-12' : 'col-6'}  d-flex justify-content-center align-items-center`}
      >
        <div className="w-50 mb-5">
          <h1 className="mb-5">
            <PortalIcon
              name="re"
              height={50}
              className="portal-fill-charcoal"
            />
            {t('logo')}
          </h1>
          <form onSubmit={loginFormik.handleSubmit}>
            <PortalInput
              spanText={t('loginPage.email')}
              type="email"
              name="email"
              value={loginFormik.values.email}
              onChange={loginFormik.handleChange}
              onBlur={loginFormik.handleBlur}
              error={loginFormik.errors.email}
            />
            <PortalInput
              spanText={t('loginPage.password')}
              type="password"
              name="password"
              value={loginFormik.values.password}
              onChange={loginFormik.handleChange}
              onBlur={loginFormik.handleBlur}
              error={loginFormik.errors.password}
            />
            <PortalButton type="submit" text={t('loginPage.login')} />
          </form>
        </div>
      </div>
      {context?.isMobile ? null : (
        <div className="col-6">
          <img src={image} className="login-image" alt="revive" />
        </div>
      )}
    </div>
  )
}

export default Login
