import React, { useContext } from 'react'
import { useLogoutMutation } from '../../api/api'
import { PortalContext } from '../../PortalContext'
import PortalIcon from '../../utils/components/PortalIcon'
import { useTranslation } from 'react-i18next'

const LogoutButton = () => {
  const context = useContext(PortalContext)
  const [logout] = useLogoutMutation()
  const { t } = useTranslation()

  const handleLogout = async () => {
    await logout().then(async () => {
      await context?.setSession(null)
      await window.location.reload()
    })
  }

  return (
    <button onClick={handleLogout} className="btn btn-sm w-100 sidebar-button">
      <PortalIcon name={'logout'} className={'m-2'} width={14} height={14} />
      {!context?.isMobile ? t('sidebar.logout') : ''}
    </button>
  )
}

export default LogoutButton
