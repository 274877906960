import React, { useState } from 'react'
import OrdersTable from './OrdersTable'
import PortalDateFilter from '../../../utils/components/PortalDateFilter'
import { useGetStatusTrackerQuery } from '../../../api/api'
import PortalLoader from '../../../utils/components/PortalLoader'
import PortalStatusTracker from '../../../utils/components/PortalStatusTracker'
import { formatOrderStatusTracker } from '../../../utils/formatStatusTracker'
import PortalSearch from '../../../utils/PortalSearch'

const OrdersPage = () => {
  const [startDate, setStartDate] = useState<null | Date>(null)
  const [endDate, setEndDate] = useState<null | Date>(null)
  const [search, setSearch] = useState<null | string>(null)
  const { data: trackerList, isLoading } = useGetStatusTrackerQuery()

  if (isLoading) {
    return <PortalLoader />
  }

  return (
    <div>
      <h1 className="page-title">Orders</h1>
      <PortalDateFilter
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        intervals={[
          'allTime',
          'lastWeek',
          'lastMonth',
          'lastQuarter',
          'lastYear',
          'custom',
        ]}
      />
      {trackerList && (
        <div className="mt-5">
          <PortalStatusTracker
            trackerList={formatOrderStatusTracker({ trackerList })}
          />
        </div>
      )}
      <div className="col-6 mt-5 d-flex">
        <PortalSearch value={search} onChange={setSearch} />
      </div>
      <div className="mt-5">
        <OrdersTable startDate={startDate} endDate={endDate} search={search} />
      </div>
    </div>
  )
}

export default OrdersPage
