import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

export const useLoginSchema = () => {
  const { t } = useTranslation('', { keyPrefix: 'validation.login' })

  return Yup.object({
    email: Yup.string().email(t('emailRequired')).required(t('emailInvalid')),
    password: Yup.string().required(t('passwordRequired')),
  })
}
