import { format } from 'date-fns'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { useTranslation } from 'react-i18next'

type Interval =
  | 'allTime'
  | 'lastWeek'
  | 'lastMonth'
  | 'lastQuarter'
  | 'lastYear'
  | 'custom'

const RANGES: {
  [id: string]: {
    text: string
    startDate: () => null | Date
    endDate: () => null | Date
  }
} = {
  allTime: {
    text: 'All Time',
    startDate: () => null,
    endDate: () => null,
  },
  lastWeek: {
    text: 'Last Week',
    startDate: () => {
      const now = new Date()
      return new Date(now.setDate(now.getDate() - now.getDay()))
    },
    endDate: () => new Date(),
  },
  lastMonth: {
    text: 'Last Month',
    startDate: () => {
      const now = new Date()
      return new Date(now.getFullYear(), now.getMonth(), 1)
    },
    endDate: () => new Date(),
  },
  lastQuarter: {
    text: 'Last Quarter',
    startDate: () => {
      const now = new Date()
      return new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1)
    },
    endDate: () => new Date(),
  },
  lastYear: {
    text: 'Last Year',
    startDate: () => {
      const now = new Date()
      return new Date(now.getFullYear(), 0, 1)
    },
    endDate: () => new Date(),
  },
  custom: { text: 'Custom', startDate: () => null, endDate: () => null },
}

const PortalDateFilter = ({
  intervals,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  intervals: Array<Interval>
  startDate: null | Date
  endDate: null | Date
  setStartDate: (d: null | Date) => void
  setEndDate: (d: null | Date) => void
}) => {
  const [selected, setSelected] = useState<Interval>(intervals[0])
  const { t } = useTranslation()

  useEffect(() => {
    if (selected !== 'custom') {
      setStartDate(RANGES[selected].startDate())
      setEndDate(RANGES[selected].endDate())
    }
  }, [selected])

  const handleChangeDate = (
    event: SyntheticEvent,
    picker: { startDate: Date; endDate: Date },
  ) => {
    setStartDate(new Date(picker.startDate))
    setEndDate(new Date(picker.endDate))
    setSelected('custom')
  }

  const filterButton = (i: Interval) => {
    return (
      <button
        key={intervals.indexOf(i)}
        type="button"
        onClick={() => {
          if (i !== 'custom') {
            setSelected(i)
          }
        }}
        className={`btn btn-small portal-button-secondary ${i === selected ? 'selected' : ''}`}
      >
        {t(`filter.${i}`)}
      </button>
    )
  }

  return (
    <div>
      <div className="btn-group date-filter mb-2" role="group">
        {intervals.map((i) => {
          return i === 'custom' ? (
            <DateRangePicker
              key={intervals.indexOf(i)}
              onApply={handleChangeDate}
              initialSettings={{
                locale: { format: 'MMMM D, yyyy' },
                maxDate: new Date(),
              }}
            >
              {filterButton(i)}
            </DateRangePicker>
          ) : (
            filterButton(i)
          )
        })}
      </div>
      <br />
      {startDate && endDate ? (
        <span className="portal-text-warmGray">
          {format(startDate, 'MMMM d, yyyy')} -
          {selected === 'custom' ? '' : t('filter.today')}
          {format(endDate, 'MMMM d, yyyy')}
        </span>
      ) : null}
    </div>
  )
}

export default PortalDateFilter
