import React from 'react'

const PortalInput = ({
  spanText,
  type,
  name,
  value,
  onChange,
  onBlur,
  error,
}: {
  spanText: string
  type: string
  name: string
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur: React.FocusEventHandler<HTMLInputElement>
  error: string | undefined
}) => {
  return (
    <div>
      <span className="mt-5">{spanText}</span>
      <input
        type={type}
        name={name}
        className="form-control mb-2"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <span className="portal-text-crimsonRed">{error}</span>
    </div>
  )
}

export default PortalInput
