import React from 'react'
import { Link } from 'react-router-dom'

const PortalHeader = ({
  paths,
}: {
  paths: { text: string; path?: string }[]
}) => {
  return (
    <div className="d-inline-flex">
      {paths.map((p) => {
        const { text, path } = p
        const key = paths.indexOf(p)
        if (path) {
          return (
            <Link key={key} to={path} className="portal-color-charcoal">
              <h1>{text}/</h1>
            </Link>
          )
        } else {
          return <h1 key={key}>{text}</h1>
        }
      })}
    </div>
  )
}

export default PortalHeader
