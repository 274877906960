import { format } from 'date-fns'
import { RetailerLocation, ReviveOrder } from './interfaces'

export interface OrderInterface {
  id: number
  hashId: string
  retailerId: number
  retailerLocationId: number
  orderStatus: string
  createdAt: string
  shippingAddressId: number
  shippingPhone: null
  shippingCourierOptionId: number
  orderType: string
  retailerAssociate: null
  estTotal: number
  patterningFee: null
  customerBalance: number
  rushFee: null
  paymentTotal: null
  invoiceTotal: null
  insurance: true
  insuranceFee: null
  alterationTotal: null
  paymentDate: null
  dueDate: null
  discountId: null
  discountAmount: null
  shippingFee: null
  garmentsNumber: number
  appointmentId: null
  appointmentFee: null
  appointmentPrimary: false
  bulkShipmentsOrders: []
  garmentInvoicedAmount: null
  firstAlterationGarmentPattern: null
  entityType: string
  tags: string[]
  partnerStatus: string
  garmentsQuantityTotal: null
  reviveOrderId: number
  isRush: boolean
  referenceRevivePatternId: null
  warehouse?: {
    id: number
    name: string
  }
  retailerLocation?: RetailerLocation
  reviveOrder?: ReviveOrder
  shippedTo: string
  shipedFrom: string
}

export class OrderClass {
  object: OrderInterface
  shippedTo: string
  shipedFrom: string
  createdAt: string
  orderId: number
  constructor(order: OrderInterface) {
    this.object = order
    this.shipedFrom = order.retailerLocation?.name || ''
    this.shippedTo = order.warehouse?.name || ''
    this.createdAt = format(new Date(order.createdAt), 'MMMM d, yyyy')
    this.orderId = order.reviveOrder?.orderId as number
  }
}
