import React from 'react'
import PortalIcon from './PortalIcon'

const PortalAlertMessage = ({
  text,
  color,
}: {
  text: string
  color: string
}) => {
  return (
    <p className="d-flex align-items-center ">
      <PortalIcon
        name="warningTriangle"
        height={14}
        width={14}
        className={`mb-1 me-1 ${color}`}
      />
      <span className={`text-size-16 ${color}`}>{text}</span>
    </p>
  )
}

export default PortalAlertMessage
